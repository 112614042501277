<template>
  <div id="pageUsuairo">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg9 sm12 xs12>
          <usuario-table></usuario-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import UsuarioTable from "@/components/usuarios/tableUsuarios"
export default {
  components: {
    UsuarioTable
  }
}
</script>
