<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-text-field
            flat
            solo
            prepend-icon="search"
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-btn @click="cadastro" icon>
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="usuarios"
          :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td style="display:none;">{{ props.item.id }}</td>
            <td style="display:none;">{{ props.item.idRole }}</td>
            <td style="display:none;">{{ props.item.idFundionario }}</td>
            <td>{{ props.item.nomeFuncionario }}</td>
            <td>{{ props.item.userName }}</td>
            <td>{{ props.item.role }}</td>
            <td class="text-xs-right">
              <v-btn @click="editar(props.item.id)" depressed icon dark color="primary" small>
                <v-icon small>edit</v-icon>
              </v-btn>
              <v-btn @click="view(props.item.id)" depressed icon dark color="primary" small>
                <v-icon small>remove_red_eye</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>
<script>
import { ServicoUsuario } from "../../servicos/servicoUsuario"
const servicoUsuario = new ServicoUsuario()
export default {
  data() {
    return {
      search: "",
      usuarios: [],
      headers: [
        { text: "Funcionario", align: "left", value: "nome" },
        { text: "Nome de usuário", align: "left", value: "cargo" },
        { text: "Perfil", align: "left", value: "perfil" },
        { text: "", value: "action", align: "right" }
      ]
    }
  },
  created() {
    servicoUsuario.buscarUsuarios().then(
      res => {
        if (res.status === 200) {
          this.usuarios = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    editar(id) {
      this.$router.push({ name: "edit-usuario", params: { id: id } })
    },
    view(id) {
      this.$router.push({ name: "view-usuario", params: { id: id } })
    },
    cadastro() {
      this.$router.push({ name: "cadastro-usuario" })
    }
  }
}
</script>
